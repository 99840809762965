import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../shared/constants';
import { ButtonTypes, TableHeadButton } from '../../../shared/modules/datatable-v3/types';

@Component({
  selector: 'rw-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss'],
})
export class FilterTagComponent {

  langCss: string;

  clearButtonType: string = ButtonTypes.GreyTransparent;

  @Input()
  options: FilterOption[] = [];

  @Input() clearButton: TableHeadButton;

  @Input() showClearButton: boolean = false;

  @Output() tagRemoved = new EventEmitter<{
    index: number;
    option: FilterOption;
    options: FilterOption[];
  }>();

  @Output() clearButtonClicked = new EventEmitter<void>();

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    const lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(lng);
    this.langCss = lng === 'en' ? 'ltr' : 'rtl';
  }

  removeTag(index: number, option: FilterOption): void {
    this.options.splice(index, 1);
    this.tagRemoved.emit({ index, option, options: this.options });
  }
}

export interface FilterOption {
  label: string;
  valueName: string;
  value: any[];
  key: string;
  coupled: boolean;
  showDeleteButton: boolean;
}
