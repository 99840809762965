import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule, ToastModule as ToastModulePrimeng } from 'primeng/toast';

import { AccordionModule } from 'primeng/accordion';
import {
  ConfirmationService,
  FilterService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';

import { TranslateModule } from '@ngx-translate/core';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DetailsFooterComponent } from './components/details-footer/details-footer.component';
import { EmptyPageComponent } from './components/empty-page/empty-page.component';
import { ImageContainerComponent } from './components/image-container/image-container.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { InputComponent } from './components/input/input.component';
import { InputV2Component } from './components/input-v2/input-v2.component';
import { ButtonComponent } from './components/button/button.component';
import { SplitButtonComponent } from './components/split-button/split-button.component';
import { FilterDropdownComponent } from './components/filter-dropdown/filter-dropdown.component';
import { FilterTagComponent } from './components/filter-tag/filter-tag.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { RewaaToggleSwitchComponent } from './components/rewaa-toggle-switch/rewaa-toggle-switch.component';
import { SimpleTableFooterComponent } from './components/simple-table-footer/footer.component';
import { SimpleTableHeaderComponent } from './components/simple-table-header/header.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PaginatorV2Component } from './components/table/paginator-v2/paginator-v2.component';
import { PaginatorComponent } from './components/table/paginator/paginator.component';
import { TagV2Component } from './components/tag-v2/tag-v2.component';
import { TagComponent } from './components/tag/tag.component';
import { DateAgoDirective } from './directives/date-ago.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { BreadcrumbModule } from './modules/breadcrumb';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { CustomLoaderService } from './services/custom-loader.service';
import { LoadingSkeletonService } from './services/loading-skeleton.service';

@NgModule({
  providers: [
    DialogService,
    FilterService,
    MessageService,
    PrimeNGConfig,
    ConfirmationService,
    DynamicDialogConfig,
    DynamicDialogRef,
    CustomLoaderService,
    LoadingSkeletonService,
  ],
  declarations: [
    OtpInputComponent,
    AutoCompleteComponent,
    CalendarComponent,
    DateFilterComponent,
    DetailsFooterComponent,
    EmptyPageComponent,
    ImageContainerComponent,
    ImageUploadComponent,
    InputComponent,
    InputV2Component,
    ButtonComponent,
    SplitButtonComponent,
    FilterDropdownComponent,
    FilterTagComponent,
    InputErrorComponent,
    InputNumberComponent,
    RewaaToggleSwitchComponent,
    SimpleTableFooterComponent,
    SimpleTableHeaderComponent,
    SpinnerComponent,
    PaginatorComponent,
    PaginatorV2Component,
    TagComponent,
    TagV2Component,
    DateAgoDirective,
    DebounceClickDirective,
    CustomLoaderComponent,
  ],

  imports: [
    CommonModule,
    FormsModule,
    CalendarModule,
    MultiSelectModule,
    RadioButtonModule,
    ButtonModule,
    RouterModule,
    PaginatorModule,
    ReactiveFormsModule,
    ChartModule,
    CheckboxModule,
    ProgressSpinnerModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    ListboxModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RippleModule,
    ScrollPanelModule,
    SelectButtonModule,
    SkeletonModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TreeTableModule,
    TooltipModule,
    InputSwitchModule,
    VirtualScrollerModule,
    DynamicDialogModule,
    BreadcrumbModule,
    ToastModulePrimeng,
    AccordionModule,
    CardModule,
    ButtonModule,
    TabMenuModule,
    InputTextareaModule,
    DragDropModule,
    TabViewModule,
    ConfirmDialogModule,
    ProgressBarModule,
    BadgeModule,
    CarouselModule,
    TimelineModule,
    DataViewModule,
    SidebarModule,
    AutoCompleteModule,
    ChipModule,
    ToastModule,
    ChipsModule,
    FieldsetModule,
    FileUploadModule,
    MenubarModule,
    ToolbarModule,
    ContextMenuModule,
    SliderModule,
    TranslateModule,
  ],

  exports: [
    OtpInputComponent,
    AutoCompleteComponent,
    CalendarComponent,
    DateFilterComponent,
    DetailsFooterComponent,
    EmptyPageComponent,
    ImageContainerComponent,
    InputComponent,
    InputV2Component,
    ButtonComponent,
    SplitButtonComponent,
    FilterDropdownComponent,
    FilterTagComponent,
    InputErrorComponent,
    InputNumberComponent,
    RewaaToggleSwitchComponent,
    SimpleTableFooterComponent,
    SimpleTableHeaderComponent,
    SpinnerComponent,
    PaginatorComponent,
    PaginatorV2Component,
    TagComponent,
    TagV2Component,
    DateAgoDirective,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    ListboxModule,
    MenuModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    RippleModule,
    ScrollPanelModule,
    SelectButtonModule,
    SkeletonModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TreeTableModule,
    TooltipModule,
    InputSwitchModule,
    VirtualScrollerModule,
    DynamicDialogModule,
    BreadcrumbModule,
    ToastModulePrimeng,
    AccordionModule,
    CardModule,
    ButtonModule,
    TabMenuModule,
    InputTextareaModule,
    DragDropModule,
    TabViewModule,
    ConfirmDialogModule,
    ProgressBarModule,
    BadgeModule,
    CarouselModule,
    TimelineModule,
    DataViewModule,
    SidebarModule,
    AutoCompleteModule,
    ChipModule,
    ToastModule,
    ChipsModule,
    FieldsetModule,
    FileUploadModule,
    MenubarModule,
    ToolbarModule,
    ContextMenuModule,
    SliderModule,
    DebounceClickDirective,
  ],
})
export class UiModule {}
