import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PublishResponse } from 'src/app/shared/model/PublishResponse';
import { VariantToTrack } from 'src/app/shared/model/VariantToTrack';
import { IProductSerachFilter } from 'src/app/shared/model/product-search-and-filter';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { UploadFile } from '../../../shared/model/UploadFile';
import { QueryByPage } from '../../../shared/services/pagination-interface';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import { Product } from '../../model/product';
import { ProductVariant } from '../../model/product-variant';
import { DeleteResponse } from '../../variants/services/delete-response';
import {
  GetVariantByIdForPOSParams,
  GetVariantByIdForPOSResponse,
} from './types/product.service.types';
import { BYPASS_NORMAL } from '../../../shared/interceptors/http-error.interceptor';

const API_URL = '/api';

@Injectable()
export class ProductService implements QueryByPage<Product> {
  private productListV4IsEnabled = false;

  constructor(
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
  ) {}

  checkProductListV4Validity(): Observable<boolean> {
    return this.featureFlagService.isEnabled(FeatureFlagEnum.CustomFields).pipe(
      switchMap((customFieldsValue: boolean) => {
        if (customFieldsValue) {
          return of(customFieldsValue);
        }
        return this.featureFlagService.isEnabled(
          FeatureFlagEnum.ProductListingV4,
        );
      }),
    );
  }

  getAnyPageByQuery(
    query: IProductSerachFilter,
  ): Observable<{ result: Product[]; total: number }> {
    const productQueryParams = new HttpParams()
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      )
      .set('search', query.search || '')
      .set('scannerCode', query.scannerCode || '')
      .set('channel', query.channel || '')
      .set('type', query.type || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10')
      .set('sortBy', query.sortBy || '')
      // .set('getVariants', query.getVariants || 'true')
      .set('sortDirection', query.sortDirection?.toString() || '')
      .set('filters', query.filters?.toString() || '');
    return this.checkProductListV4Validity().pipe(
      switchMap((flagValue) => {
        const version = flagValue ? 'v4' : 'v3';
        const url = `${API_URL}/products-${version}`;
        return this.http.get<{ result: Product[]; total: number }>(url, {
          params: productQueryParams,
          context: new HttpContext().set(BYPASS_NORMAL, true),
        });
      }),
    );
  }

  getPaginatedProductsV3(
    query: IProductSerachFilter,
  ): Observable<{ result: Product[]; total: number }> {
    const productQueryParams = new HttpParams()
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate ? query.dates.toDate.toString() : '',
      )
      .set('search', query.search || '')
      .set('scannerCode', query.scannerCode || '')
      .set('channel', query.channel || '')
      .set('type', query.type || '')
      .set('offset', query.offset || '0')
      .set('limit', query.limit || '10')
      .set('sortBy', query.sortBy || '')
      .set('sortDirection', query.sortDirection?.toString() || '')
      .set('filters', query.filters?.toString() || '')
      .set('columns', query.columns?.toString() || '');
    const url = `${API_URL}/products-v3`;
    return this.http.get<{ result: Product[]; total: number }>(url, {
      params: productQueryParams,
      context: new HttpContext().set(BYPASS_NORMAL, true),
    });
  }

  saveProduct(product, context?): Observable<Product> {
    return this.http.post<Product>(`${API_URL}/products`, product, {
      context,
    });
  }

  updateProduct(product: Product): Observable<Product> {
    return this.http.put<Product>(`${API_URL}/products/${product.id}`, product);
  }

  saveProductImages(productId, files): Observable<any> {
    return this.http.post<UploadFile[]>(
      `${API_URL}/products/${productId}/images`,
      files,
    );
  }

  deleteProducts(listOfIds: Array<number>): Observable<DeleteResponse> {
    const ids = listOfIds.join(',');
    return this.http.delete<DeleteResponse>(`${API_URL}/products?ids=${ids}`);
  }

  getVariantsByProductId(productId): Observable<ProductVariant[]> {
    return this.http.get<ProductVariant[]>(
      `${API_URL}/products/${productId}/variants`,
    );
  }

  getVariantByIdForPOS(
    payload: GetVariantByIdForPOSParams,
  ): Observable<GetVariantByIdForPOSResponse> {
    const { variantId } = payload;
    const path = `/pos/variants/${variantId}`;

    let httpPrams = new HttpParams()
      .set('locationId', payload.locationId)
      .set('variantId', payload.variantId)
      .set('offset', payload.offset)
      .set('limit', payload.limit);

    if (payload.orderBy) {
      httpPrams = httpPrams.append('orderBy', payload.orderBy);
      httpPrams = httpPrams.append('order', payload.order);
    }

    if (payload.filter) {
      httpPrams = httpPrams.append('filter', payload.filter);
    }

    const options = { params: httpPrams };

    return this.http.get<GetVariantByIdForPOSResponse>(
      `${API_URL}${path}`,
      options,
    );
  }

  getProductById(productId): Observable<Product> {
    return this.http.get<Product>(`${API_URL}/products/${productId}`);
  }

  getProductVariantsByIds(
    ids = [],
  ): Observable<{ result: ProductVariant[]; total: number }> {
    return this.http.post<{ result: ProductVariant[]; total: number }>(
      `${API_URL}/products/list`,
      {
        filters: {
          id: [{ matchMode: 'in', value: ids }],
        },
        limit: ids.length,
      },
    );
  }

  getProductImages(productId): Observable<UploadFile[]> {
    return this.http.get<UploadFile[]>(
      `${API_URL}/products/${productId}/images`,
    );
  }

  publishProducts(products, appId): Observable<PublishResponse> {
    return this.http.put<PublishResponse>(
      `${API_URL}/products/publish?app=${appId}`,
      products,
    );
  }

  unPublishProducts(
    products,
    appName,
  ): Observable<{
    hasErrors: boolean;
    products: Product[];
    unpublishedProducts: number;
    productsWithOrders: number;
  }> {
    return this.http.put<{
      hasErrors: boolean;
      products: Product[];
      unpublishedProducts: number;
      productsWithOrders: number;
    }>(`${API_URL}/products/unPublish?app=${appName}`, products);
  }

  saveProductTrack(
    variantTracks: VariantToTrack,
  ): Observable<VariantToTrack[]> {
    return this.http.post<VariantToTrack[]>(
      `${API_URL}/variant-track`,
      variantTracks,
    );
  }

  getProductReferences(productId: number): Observable<any> {
    return this.http.get<any>(`${API_URL}/products/${productId}/references`);
  }

  getProductWithStock(productId: number): Observable<Product> {
    return this.http.get<any>(`${API_URL}/products/${productId}/stocks`);
  }

  async getProductImagesByIds(idList: number[]): Promise<any> {
    return this.http
      .post<any>(`${API_URL}/products/none/images/all`, { ids: idList })
      .toPromise();
  }

  async getVariantImagesByIds(idList: number[]): Promise<any> {
    return this.http
      .get<any>(`${API_URL}/variants/images?ids=${idList.toString()}`)
      .toPromise();
  }

  assignProductToCategory(productId, payload): Observable<any> {
    return this.http.put<PublishResponse>(
      `${API_URL}/products/${productId}/category`,
      payload,
    );
  }

  deleteVariantByProductId(
    productId: number,
    variantId: number,
  ): Observable<DeleteResponse> {
    return this.http.delete<DeleteResponse>(
      `${API_URL}/products/${productId}/variants/${variantId}`,
    );
  }
}
