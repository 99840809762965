import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

// Module
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../../ui/ui.module';

// Components
import { DatatableV3Component } from './datatable-v3.component';
import { TableHeadComponent } from './components/table-head/table-head.component';
import { TableDesktopComponent } from './components/table-desktop/table-desktop.component';
import { TableMobileDefaultComponent } from './components/table-mobile-default/table-mobile-default.component';
import { TableMobileExpansionComponent } from './components/table-mobile-expansion/table-mobile-expansion.component';

import { FilterDialogComponent } from './dialogs/filter-dialog/filter-dialog.component';
import { TableFilterComponent } from './components/table-filter/table-filter.component';
import { ResolveItemDisabled } from './pipes/item-disabled.pipe';
import { TableTimeSelectorComponent } from './components/table-time-selector/table-time-selector.component';
import { DatatableRowDirective } from './directives/datatable-row.directive';
import { ResolveItemName } from './pipes/item-name.pipe';

@NgModule({
  declarations: [
    ResolveItemDisabled,
    ResolveItemName,
    DatatableV3Component,
    TableHeadComponent,
    TableDesktopComponent,
    TableMobileDefaultComponent,
    TableMobileExpansionComponent,
    FilterDialogComponent,
    TableFilterComponent,
    TableTimeSelectorComponent,
    DatatableRowDirective,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    UiModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    DatatableV3Component,
    TableHeadComponent,
    TableDesktopComponent,
    TableMobileExpansionComponent,
    TableMobileDefaultComponent,
  ],
})
export class DatatableV3Module {}
