import { ProductTypes, ProductEnums, TaxType } from '@rewaa-team/types';
import {
  Promotion,
  PromotionDetails,
  PromotionGroup,
  PromotionType,
} from '../promotions/types';
import { DiscountType, TaxMethod, ErrorCodesConstant } from './enums';
import { AmountType } from '../common/enums';
import { InvoiceEnums } from '..';
import { CustomFieldData } from '../invoice/types';

export interface Variant {
  id: number;
  name: string;
  productName: string;
  productId: number;
  variantId: number;
  sku: string;
  tax: Tax;
  /**
   *  exclusive retail price of the variant at the stock location
   * */
  retailPrice: number;
  /**
   *  exclusive wholesale price of the variant at the stock location
   * */
  wholesalePrice: number;
  /**
   *  cost of the variant
   * required for the cost > price check
   * */
  cost: number;
  /**
   *  stock location quantity of the variant
   * required for the sell when qty <= 0 check
   */
  availableQuantity: number;
  productType: ProductEnums.ProductType;
  trackType?: ProductEnums.TrackType;
  type: ProductEnums.VariantType;
  trackDetails: TrackDetail[];
  composites: Composite[];
  packs: Pack[];
  extras: Extra[];
  eCards: ECard[];
  /**
   *  An array of categories ids to which the product belongs
   * Will contain parent categories if the product belongs to a sub-category.
   * Required for promotions.
   */
  categoryIds: number[];
  promotions: Promotion[];
  isSellable: boolean;
  imageUrl?: string;
  unit: '' | ProductEnums.WeightedVariantUnit;
  manageStockLevel: boolean;
  customFieldsData?: CustomFieldData[];
}

export interface InvoiceVariant {
  id: number;
  variantId: number;
  name: string;
  sku: string;
  tax: Tax;
  originalPrice: number;
  sellPrice: number;
  cost: number;
  quantity: number;
  type: ProductEnums.ProductType;
  trackType?: ProductEnums.TrackType;
  trackDetails: TrackDetail[];
  composites: Composite[];
  packs: Pack[];
  extras: Extra[];
  eCards: ECard[];
  unit: '' | ProductEnums.WeightedVariantUnit;
}

export interface Composite {
  id: number;
  variantId: number;
  productId: number;
  availableQuantity: number;
  name: string;
  rate: number;
  sku: string;
  cost: number;
  type: ProductEnums.VariantType;
}
export interface Pack {
  id: number;
  name: string;
  variantId: number;
  productId: number;
  availableQuantity: number;
  rate: number;
  cost: number;
  sku: string;
  type: ProductEnums.ProductType;
}
export interface TrackDetail {
  id: number;
  trackNo: string;
  availableQuantity: number;
  quantity: number;
}

export interface Extra {
  name: string;
  id: number;
  price: number;
  cost: number;
  tax: Tax;
  rate: number;
  productId?: number;
  variantId?: number;
  productType?: ProductEnums.ProductType;
  type?: ProductEnums.VariantType;
  isPartOfOtherProduct: boolean;
  sku: string;
  availableQuantity: number;
  imageUrl?: string;
  packs: Pack[];
  unit: '' | ProductEnums.WeightedVariantUnit;
  manageStockLevel: boolean;
}

export interface LineItem {
  /**
   * uuid
   */
  id: string;
  variantId: number;
  productId: number;
  sku: string;
  index: number;

  name: string;
  productName: string;
  imageUrl?: string;
  availableQuantity: number;

  // variables that are updated or calculated
  quantity: number;
  cost: number;
  priceTaxInclusive: LineItemPrice;
  priceTaxExclusive: LineItemPrice;

  /**
   * product discount + promotion on all qty of the item | exclusive
   */
  totalDiscount: number;
  /**
   * Represents an array of the discounts applied on a line item.
   * Discount can be of different types e.g Product, Invoice and Promotion discount
   * This is the only place in a line item where we show a invoice level discount
   */
  discounts: Discount[];
  tax: LineItemTax;
  totalTax: number;
  subtotal: number;
  subtotalWithTax: number; // to show the lineItem subtotal in inclusive mode
  totalWithoutTax: number; // to show the lineItem total in exclusive mode
  total: number;

  /**
   *  An array of categories ids to which the product belongs
   * Will contain parent categories if the product belongs to a sub-category.
   * Required for promotions.
   */
  categoryIds: number[];
  promotionType?: PromotionType;
  promotionDetails: PromotionDetails[];
  promotions: Promotion[];

  // need these to maintain the qty <= 0 check
  // and to show details on FE
  productType: ProductEnums.ProductType;
  trackType?: ProductEnums.TrackType;
  type: ProductEnums.VariantType;
  trackDetails: TrackDetail[];
  composites: Composite[];
  packs: Pack[];
  eCards: ECard[];

  // note: even though currently all weighted variants have the same unit
  // adding it on a lineitem level for showing on the receipt
  unit: '' | ProductEnums.WeightedVariantUnit;

  extras: ExtraLineItem[];
  manageStockLevel: boolean;
  customFieldsData?: CustomFieldData[];
}

export type SellApiLineItem = Omit<LineItem, 'promotions'>;

export interface ECard {
  id: number;
  code: string;
  quantity: number;
}

export interface LineItemFindByParam {
  id?: string | number;
  variantId?: number;
  sku?: string;
  index?: number;
  productId?: number;
}

export interface LineItemPrice {
  /**
   *  selling price set by the user
   */
  base: number;
  retail: number;
  wholesale: number;
  /**
   * total price of the extras
   */
  extrasTotal: number;
  /**
   *  unit price after we apply promo or product level discount - NO GENERAL DISCOUNT HERE
   */
  final: number;
}

export type ExtraLineItem = Omit<
  LineItem,
  | 'categoryIds'
  | 'promotions'
  | 'promotionDetails'
  | 'trackDetails'
  | 'extras'
  | 'composites'
  | 'unitExtrasTotal'
  | 'productType'
  | 'eCards'
  | 'id'
  | 'productId'
  | 'type'
  | 'productName'
> &
  Omit<Extra, 'price' | 'tax'>;

export interface Discount {
  id?: number;
  name: string;
  /**
   *  percentage / 100
   * If you apply a 23% discount this will say .23
   */
  rate: number;
  /**
   *  Fixed or Percentage
   */
  amountType: AmountType;
  /**
   *  Product or Invoice or Promotion
   */
  type: DiscountType;
  /**
   *  Unit level discount amount. Exclusive
   */
  unitAmount: number;
  /**
   *  total discount amount. unit level discount * qty. Exclusive
   */
  total: number;
  /**
   *  total discount amount. unit level discount * qty. Inclusive
   */
  totalWithTax: number;
}

export type DiscountSummary = Record<DiscountType, number>;
export interface AmountSummary {
  totalDiscounts: number;
  totalTax: number;
  /**
   * the total without any promotions discounts and tax
   */
  subtotal: number;
  subtotalWithTax: number;
  /**
   * tax exclusive total
   * all discounts and promotions are applied
   * totalTaxExclusive = subtotal - totalDiscounts
   */
  totalWithoutTax: number;
  /**
   * tax inclusive total
   * total = totalWithoutTax + totalTax
   */
  total: number;
}

export type PromotionSummary = Discount[];

export interface Salesman {
  id: number;
  name: string;
  maxDiscount: number;
}

export interface Customer {
  id: number;
  code: string;
  name: string;
  mobileNumber: string;
}

export interface Tax {
  id: number;
  name: string;
  code: string;
  /**
   * percentage / 100
   * rate in decimal e.g 0.15 if tax is 15%
   */
  rate: number;
  taxLines: TaxLine[];
}

export interface TaxLine {
  id: number;
  name: string;
  /**
   *  percentage / 100
   * If you apply a 23% discount this will say .23
   */
  rate: number;
  taxId: number;
  type: TaxMethod;
}
export interface LineItemTaxLine extends TaxLine {
  unitAmount: number;
  total: number;
}

export interface LineItemTax extends Tax {
  taxLines: LineItemTaxLine[];
  unitAmount: number;
  total: number;
}

export interface TaxLineBreakdown extends TaxLine {
  total: number;
}

export interface TaxBreakdown extends Tax {
  taxLines: TaxLineBreakdown[];
  taxableAmount: number;
  total: number;
}

export type TaxSummary = TaxBreakdown[];

export interface ValidationSettings {
  allowCostGreaterThanPrice: boolean;
  allowInsufficientStock: boolean;
  discountLimit: number;
}
export interface Cart {
  id: string;
  priceMode: InvoiceEnums.PriceMode;
  /**
   * Inclusive | Exclusive
   *
   * this is the tax sell status
   * if it is inclusive then all the prices entered by the user are inclusive
   * and will have to be added accordingly
   */
  taxMode: TaxType;
  note: string;
  lineItems: LineItem[];
  discount: Discount;
  amountSummary: AmountSummary;
  discountSummary: DiscountSummary;
  taxSummary: TaxSummary;
  categories: ProductTypes.Category[];
  promotionGroups: PromotionGroup[];
  // we show this on the cart FE but not the receipt
  promotionSummary: PromotionSummary;
  validationSettings: ValidationSettings;
  validationErrors: ValidationErrors;
  applyPromotions: boolean;
}

export interface Fee {
  referenceId?: number;
  name?: string;
  amount: string;
  amountType: AmountType;
  type: InvoiceEnums.InvoiceFeeType;
}

export interface SellApiCart {
  id: string;
  priceMode: InvoiceEnums.PriceMode;
  lineItems: SellApiLineItem[];
  discount: Discount;
  amountSummary: AmountSummary;
  discountSummary: DiscountSummary;
  taxSummary: TaxBreakdown[];
  promotionGroups: PromotionGroup[];
  fees?: Fee[];
  taxMode?: TaxType;
}

// ------------ CART VALIDATION SERVICE TYPES ----------------

export interface LineItemError {
  [ErrorCodesConstant.CostGreaterThanPrice]?: {
    cost: number;
    price: number;
  };
  [ErrorCodesConstant.InsufficientStock]?: {
    quantity: number;
    availableQuantity: number;
  };
  [ErrorCodesConstant.DiscountLimitExceeded]?: DiscountLimitExceededError;
  [ErrorCodesConstant.QuantityExceeded]?: number;
  [ErrorCodesConstant.PriceExceeded]?: number;
}

export type LineItemErrors = LineItemError[];
export interface ValidationErrors {
  [ErrorCodesConstant.DiscountLimitExceeded]?: DiscountLimitExceededError;
  lineItemErrors: LineItemErrors;
}

export interface DiscountLimitExceededError {
  discount: number;
  discountLimit: number;
}

export interface VariantStock {
  [id: number]: VariantStockDetails;
}

export interface VariantStockDetails {
  quantity: number;
  availableQuantity: number;
}

export interface AddReturnLineItemDiscount {
  discountedItems: number;
  discountAmount: number;
  discountType: AmountType;
}
