import {
  Component, Injector, Input, OnDestroy, OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take, takeWhile, tap } from 'rxjs/operators';
import { AuthUser } from 'src/app/auth/auth.models';
import { Product } from 'src/app/inventory/model/product';
import { AppState } from 'src/app/reducers';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UserService } from '../../../../../auth/services/user.service';
import { LocalStorageKey } from '../../../../constants';
import { LocalStorageService } from '../../../../../core/services/local-storage.service';

@Component({
  selector: 'rw-delete-bulk-products',
  templateUrl: './delete-bulk-products.component.html',
  styleUrls: ['./delete-bulk-products.component.scss'],
})

export class DeleteBulkProductsComponent implements OnInit, OnDestroy {
  @Input() content: any = {};

  @Input() dialog = true;

  lng: string;

  langCss: string;

  products: Product[];

  variants: Product[];

  selectedProducts: Product[];

  selectedVariants: Product[];

  $destroyed = false;

  user: AuthUser;

  element: HTMLCollectionOf<Element>;

  constructor(
    private store: Store<AppState>,
    private injector: Injector,
    private userService:UserService,
    private localStorageService: LocalStorageService,
  ) {
    this.lng = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.langCss = this.lng === 'en' ? 'ltr' : 'rtl';
  }

  ngOnInit(): void {
    this.loadContent();
    this.element = document.getElementsByClassName('modal-content');
    if (this.element.length) {
      this.element[0].classList.add('mt-150');
    }
  }

  loadContent() {
    if (this.dialog) {
      const config = <DynamicDialogConfig> this.injector.get(DynamicDialogConfig);
      if (config.data && config.data.content) {
        this.content = config.data.content;
      }
    }
    this.products = this.content.products;
    this.selectedProducts = this.products.slice();
    this.userService.getUser().pipe(
      take(1),
      tap((user) => {
        this.user = user;
      }),
      takeWhile(() => !this.$destroyed),
    ).subscribe();
  }

  ngOnDestroy() {
    this.$destroyed = true;
    this.element[0]?.classList.remove('mt-150');
  }

  onDelete() {
    // this.selectedVariants?.forEach((variant: any, index: number) => {
    //   this.productService.deleteVariantByProductId(variant.productId, variant.id)
    //     .subscribe((res) => {
    //       if ((this.selectedProducts?.length === 0) && res.success) {
    //         if (index === 0) {
    //           this.toastService.success(this.translate.instant('The products have been deleted successfully'));
    //         }
    //         if (index === this.selectedVariants.length - 1) {
    //           this.content.cancelFunction();
    //           this.content.refreshFunction();
    //         }
    //       }
    //     });
    // });
    if (this.selectedProducts?.length > 0) {
      this.content.deleteFunction(this.selectedProducts);
    } else {
      this.content.cancelFunction();
      this.content.refreshFunction();
    }
  }
}
