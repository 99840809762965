<div class="d-md-flex d-none md:flex-wrap align-items-center rw__tag-list gap-2">
    <div *ngFor="let option of options; let i = index;" class="rw__tag-teal rw__tag-no-hover rw__fs-9 fw-400 rounded-4">
        <span class="rw__py-2 rw__px-4 white-space-nowrap h-100 d-flex align-items-center">
            {{ option.label | translate }}:
            <strong class="fw-500 rw__ms-2" [ngClass]="{'label-overflowed': !option.coupled}">{{ option.valueName | translate }}</strong>
            <span class="fw-500" *ngIf="option.value.length > 1 && !option.coupled">, +{{option.value.length - 1}}</span>
        </span>
        <button *ngIf="option.showDeleteButton" type="button" [ngClass]="langCss === 'rtl' ? 'border-end-1': 'border-start-1'" (click)="removeTag(i, option)">
            <i class="fa-light fa-xmark rw__fs-9"></i>
        </button>
    </div>
    <rw-button
      *ngIf="showClearButton"
      [type]="clearButtonType"
      [size]="clearButton.size"
      [label]="clearButton.label | translate"
      [icon]="clearButton.icon"
      [permission]="clearButton.permission"
      [tracker]="clearButton.tracker"
      [badge]="clearButton.badge"
      [disabled]="clearButton.disabled"
      [loading]="clearButton.loading"
      [rounded]="clearButton.rounded"
      [responsive]="clearButton.responsive"
      (onClick)="clearButtonClicked.emit()"
    ></rw-button>
</div>
