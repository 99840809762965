/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, map } from 'rxjs/operators';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { firstValueFrom } from 'rxjs';
import { DropdownFilterOptions } from 'primeng/dropdown';
import { AuthUser } from '../../auth.models';
import { AuthService } from '../../services/auth.service';
import { RegexEnum } from '../../../shared/model/enum/RegexPattern';
import { LocalStorageKey } from '../../../shared/constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocationService } from '../../../core/services/location.service';
import { CouponCodeStatus } from '../../../shared/model/enum/enums';
import { UserType } from '../../../shared/types/auth.types';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'rw-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe
export class RegisterComponent implements OnInit, OnDestroy, AutoUnsubscribeI {
  subscriptionRefs;

  fGroup: UntypedFormGroup;

  user: AuthUser;

  isShowSpinner = false;

  errMessage: string;

  smsOtpFeatureFlag = false;

  changeLang: string;

  lang: string;

  destroyed = false;

  referralCode = '';

  invalidLicense = null;

  registerData;

  openLicenseKeyDialogVisible = false;

  countries: any[] = [];

  selectedCountry: any;

  filterValue = '';

  passwordModel: string;

  showLicenseKey = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private merchantsService: AuthService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private locationService: LocationService,
  ) {
    translate.addLangs(['ar', 'en']);
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    this.translate.use(this.lang);
    this.registerData = this.router.getCurrentNavigation()?.extras?.state;
    if (!this.lang) {
      translate.setDefaultLang('ar');
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.changeLang = 'en';
    } else {
      translate.setDefaultLang(this.lang);
      this.changeLang = this.lang === 'ar' ? 'en' : 'ar';
    }

    this.isShowSpinner = true;
    firstValueFrom(this.locationService.getCountries())
      .then((countries) => {
        this.isShowSpinner = false;
        this.countries = countries
          .map((country) => {
            const localeName =
              this.lang === 'ar'
                ? this.translate.instant(`country.${country.countryKey}`)
                : country.name;
            const countryWithLocale = { ...country, localeName };
            if (
              countryWithLocale.countryKey === 'SA' &&
              this.selectedCountry == null
            ) {
              this.selectedCountry = countryWithLocale;
            }
            return countryWithLocale;
          })
          .sort((a, b) => a.localeName.localeCompare(b.localeName));
      })
      .catch((error) => {
        this.isShowSpinner = false;
        console.info(error);
      })
      .finally(() => {
        this.isShowSpinner = false;
      });

    this.featureFlagService
      .isEnabled(FeatureFlagEnum.SmsOtp, false)
      .subscribe((smsOtpFeatureFlag) => {
        this.smsOtpFeatureFlag = smsOtpFeatureFlag;
      });

    const referralCode = this.localStorageService.getItem<string>(
      LocalStorageKey.ReferralCode,
    );
    if (referralCode && !this.referralCode) {
      this.referralCode = referralCode;
    }
  }

  // eslint-disable-next-line class-methods-use-this, @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  get firstName() {
    return this.fGroup.get('firstName');
  }

  get lastName() {
    return this.fGroup.get('lastName');
  }

  get email() {
    return this.fGroup.get('email');
  }

  get password() {
    return this.fGroup.get('password');
  }

  get companyName() {
    return this.fGroup.get('companyName');
  }

  get phone() {
    return this.fGroup.get('phone');
  }

  get licenseKey() {
    return this.fGroup.get('licenseKey');
  }

  get utmParams() {
    return this.fGroup.get('utmParams');
  }

  ngOnInit() {
    this.subscriptionRefs = this.activatedRoute.queryParams.subscribe(
      (payload) => {
        this.createForm();
        // make sure email is always in lower case
        this.subscriptionRefs = this.fGroup
          .get('email')
          .valueChanges.subscribe((event) => {
            this.fGroup
              .get('email')
              .setValue(event?.toLowerCase(), { emitEvent: false });
            if (this.registerData) {
              this.fGroup
                .get('companyName')
                .setValue(this.registerData.companyName);
              this.fGroup.get('phone').setValue(this.registerData.phone);
              this.fGroup
                .get('licenseKey')
                .setValue(this.registerData.licenseKey ?? '');
              this.selectedCountry = this.registerData.selectedCountry;
            }
          });
        this.fGroup.patchValue(payload);
        if (payload.companyName || payload.phone || payload.licenseKey) {
          Object.keys(this.fGroup.controls).forEach((key) => {
            this.fGroup.get(key).markAsDirty();
            this.fGroup.get(key).markAsTouched();
          });
        }
        // fetch license key for user scanning the STC QR code
        const licenseKey = payload.license;
        if (licenseKey) {
          this.licenseKey.setValue(licenseKey);
          this.validateLicense();
          this.handleShowLicenseKey();
        }
        if (this.areUtmParamsPresent(payload)) {
          const utmParams: string = this.getUtmParamsString(payload);
          this.utmParams.setValue(utmParams);
        }
        if(payload.ref) {
          this.referralCode = payload.ref;
        }
      },
    );
    if (this.referralCode) {
      this.fGroup.get('referralCode').setValue(this.referralCode);
    }
  }

  areUtmParamsPresent(payload): boolean {
    return (
      payload.utm_pl ||
      payload.utm_me ||
      payload.utm_ch ||
      payload.utm_pu ||
      payload.utm_ca ||
      payload.utm_ag ||
      payload.utm_co ||
      payload.utm_keyword
    );
  }

  getUtmParamsString(payload): string {
    const {
      utm_pl,
      utm_me,
      utm_ch,
      utm_pu,
      utm_ca,
      utm_ag,
      utm_co,
      utm_keyword,
    } = payload;
    const utmParams: { [key: string]: string } = {};
    if (utm_pl) utmParams.utmPlacement = utm_pl;
    if (utm_me) utmParams.utmMedium = utm_me;
    if (utm_ch) utmParams.utmChannel = utm_ch;
    if (utm_pu) utmParams.utmPublisher = utm_pu;
    if (utm_ca) utmParams.utmCampaign = utm_ca;
    if (utm_ag) utmParams.utmAdGroup = utm_ag;
    if (utm_co) utmParams.utmContent = utm_co;
    if (utm_keyword) utmParams.utmKeyword = utm_keyword;

    return Object.keys(utmParams).length === 0 ? '' : JSON.stringify(utmParams);
  }

  createForm() {
    const emailRegex = environment.allowAliasedEmails
      ? RegexEnum.laxEmail
      : RegexEnum.email;

    this.fGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      password: [
        '',
        [Validators.required, Validators.pattern(RegexEnum.password)],
      ],
      companyName: ['', [Validators.required]],
      phone: ['', [this.getPhoneNumberValidator(), Validators.required]],
      showPassword: [''],
      referralCode: [''],
      utmParams: [''],
      licenseKey: ['', [Validators.minLength(20), Validators.maxLength(20)]],
    });
  }

  validateLicense() {
    this.isShowSpinner = true;
    firstValueFrom(
      this.merchantsService.validateLicenseKey(this.licenseKey.value),
    )
      .then((res) => {
        this.isShowSpinner = false;
        if (!res.coupon || res.coupon.status !== CouponCodeStatus.NotRedeemed) {
          this.invalidLicense = this.licenseKey.value;
          this.licenseKey.markAsTouched();
          this.validateLicenseKeyInput();
          return;
        }
        this.invalidLicense = null;
      })
      .catch(() => {
        this.isShowSpinner = false;
        this.invalidLicense = this.licenseKey.value;
        this.licenseKey.markAsTouched();
        this.validateLicenseKeyInput();
      });
  }

  onLicenseKeyComplete(): void {
    if (this.licenseKey.value !== this.invalidLicense) {
      this.validateLicense();
    } else if (this.invalidLicense)
      this.licenseKey.setErrors({ notValid: true });
  }

  validateLicenseKeyInput(): void {
    if (
      this.licenseKey.value.length === 20 &&
      this.licenseKey.value === this.invalidLicense
    ) {
      setTimeout(() => this.licenseKey.setErrors({ notValid: true }));
    }
  }

  getPhoneNumberValidator() {
    return (control: FormControl) => {
      const inputValue = control.value;

      if (this.selectedCountry && this.selectedCountry?.countryKey !== 'SA') {
        const pattern = new RegExp('^[0-9]{5,13}$');
        if (!pattern.test(inputValue)) {
          return { otherThanSaudiPattern: true };
        }
      } else {
        if (!control.value.startsWith('05') && !control.value.startsWith('5')) {
          return { saudiZeroOrZeroFive: true };
        }
        if (control.value.startsWith('0')) {
          const pattern = new RegExp('^(05[0-9]{8})$');
          if (!pattern.test(inputValue)) {
            return { zeroSaudiPattern: true };
          }
        } else {
          const pattern = new RegExp('^(5[0-9]{8})$');
          if (!pattern.test(inputValue)) {
            return { saudiPattern: true };
          }
        }
      }

      return null; // Return null if validation passes
    };
  }

  myResetFunction(options: DropdownFilterOptions): void {
    options.reset();
    this.filterValue = '';
  }

  onCountrySelect(): void {
    this.phone.setValue('');
  }

  clearLicenseKey(): void {
    this.licenseKey.setValue('');
  }

  // eslint-disable-next-line consistent-return
  register(user: AuthUser) {
    // appendCountryCode
    // eslint-disable-next-line no-param-reassign
    const phoneWithoutCode = user.phone;
    user.phone = this.selectedCountry.code + user.phone;
    let gcc;
    if (this.selectedCountry.countryKey === 'SA') {
      gcc = UserType.Gcc;
    } else {
      gcc = UserType.NonGcc;
    }
    this.isShowSpinner = true;
    if (
      this.activatedRoute.snapshot.queryParams.email ===
      this.fGroup.get('email').value
    ) {
      this.isShowSpinner = false;
      return this.router.navigate(['/confirmation'], {
        queryParams: this.smsOtpFeatureFlag
          ? {
              email: this.fGroup.get('email').value,
              cc: this.selectedCountry.code,
              phone: phoneWithoutCode,
              type: gcc,
            }
          : { email: this.fGroup.get('email').value },
        state: {
          companyName: this.fGroup.get('companyName').value,
          phone: this.fGroup.get('phone').value,
          licenseKey: this.fGroup.get('licenseKey').value,
          selectedCountry: this.selectedCountry,
          password: this.fGroup.get('password').value,
        },
      });
    }
    this.subscriptionRefs = this.merchantsService
      .registerMerchant(user)
      .pipe(
        map(() => {
          this.isShowSpinner = false;
          this.router.navigate(['/confirmation'], {
            queryParams: this.smsOtpFeatureFlag
              ? {
                  email: this.fGroup.get('email').value,
                  cc: this.selectedCountry.code,
                  phone: phoneWithoutCode,
                  type: gcc,
                }
              : { email: this.fGroup.get('email').value },
            state: {
              companyName: this.fGroup.get('companyName').value,
              phone: this.fGroup.get('phone').value,
              licenseKey: this.fGroup.get('licenseKey').value,
              selectedCountry: this.selectedCountry,
              password: this.fGroup.get('password').value,
            },
          });
        }),
        catchError((err: any) => {
          this.isShowSpinner = false;
          this.errMessage = this.translate.instant(err.error.message);
          return err;
        }),
        finalize(() => {
          this.isShowSpinner = false;
        }),
      )
      .subscribe();
  }

  openLicenseKeyDialog(): void {
    this.openLicenseKeyDialogVisible = true;
  }

  closeLicenseKeyDialog(): void {
    this.openLicenseKeyDialogVisible = false;
  }

  handleShowLicenseKey(): void {
    this.showLicenseKey = true;
  }
}
