export const legacyPermissionstoNewPermissions: { [key: string]: string } = {
  'accounting_app.account_details.read': 'accounting:account-details:read',
  'accounting_app.chart_of_account.read': 'accounting:chart-of-account:read',
  'accounting_app.customer.account_statement':
    'accounting:customer-account-statement:read',
  'accounting_app.customer.opening_balance':
    'accounting:customer-opening-balance:read',
  'accounting.dashboard.read_reports': 'accounting:dashboard:read-reports',
  'accounting.dashboard.read': 'accounting:dashboard:read',
  'accounting.dashboard.add_/_withdraw_funds':
    'accounting:dashboard:add/withdraw-funds',
  'accounting_app.journal_entry.read': 'accounting:journal-entry:read',
  'accounting_app.journal_entry.add': 'accounting:journal-entry:add',
  'accounting_app.journal_entry.update': 'accounting:journal-entry:update',
  'accounting_app.opening_balance.add': 'accounting:opening-balance:add',
  'accounting_app.supplier.account_statement':
    'accounting:supplier-account-statement:read',
  'accounting_app.supplier.opening_balance':
    'accounting:supplier-opening-balance:read',
  'accounting.wallet_transactions.list':
    'accounting:wallet-transaction-list:read',
  'accounting_app.journal_entry.delete': 'accounting:journal-entry:delete',
  applications: 'applications::',
  'app.detail.read': 'applications:app-detail:read',
  'app.detail.update': 'applications:app-detail:update',
  'app.list.read': 'applications:app-list:read',
  'app.list.update': 'applications:app-list:update',
  'bonat.dashboard.read': 'bonat:dashboard:read',
  'bonat.dashboard.update': 'bonat:dashboard:update',
  'bonat.error_list.read': 'bonat:error-list:read',
  'bonat.error_list.update': 'bonat:error-list:update',
  'bonat.product_management.read': 'bonat:product-management:read',
  'bonat.product_management.update': 'bonat:product-management:update',
  'bonat.setting.read': 'bonat:setting:read',
  'bonat.setting.update': 'bonat:setting:update',
  dashboard: 'dashboard::',
  'activity.logs.read': 'dashboard:activity-logs:read',
  'activity.logs.search': 'dashboard:activity-logs:search',
  'new_dashboard.amount_collected': 'dashboard:amount-collected-v2:read',
  'dashboard.amount_collected': 'dashboard:amount-collected:read',
  'dashboard.average_items_per_sale': 'dashboard:average-items-per-sale:read',
  'dashboard.average_sales': 'dashboard:average-sales:read',
  'dashboard.expense': 'dashboard:expense:read',
  'dashboard.gross_profit': 'dashboard:gross-profit:read',
  'new_dashboard.inventory_value': 'dashboard:inventory-value-v2:read',
  'dashboard.inventory_value': 'dashboard:inventory-value:read',
  'new_dashboard.latest_orders_and_sales':
    'dashboard:latest-orders-and-sales:read',
  'new_dashboard.logs': 'dashboard:logs:read',
  'dashboard.net_income': 'dashboard:net-income:read',
  'new_dashboard.net_income': 'dashboard:net-income-v2:read',
  'new_dashboard.notifications': 'dashboard:notifications:read',
  'new_dashboard.register_chart': 'dashboard:register-chart:read',
  'dashboard.revenue': 'dashboard:revenue:read',
  'new_dashboard.sales_by_branch': 'dashboard:sales-by-branch:read',
  'new_dashboard.sales_by_category': 'dashboard:sales-by-category:read',
  'new_dashboard.sales_by_day': 'dashboard:sales-by-day:read',
  'new_dashboard.sales_by_product': 'dashboard:sales-by-product:read',
  'new_dashboard.sales_by_salesman': 'dashboard:sales-by-salesman:read',
  'new_dashboard.sales_target': 'dashboard:sales-target:read',
  'new_dashboard.sales_transaction': 'dashboard:sales-transaction:read',
  'dashboard.top_5_products': 'dashboard:top-5-cards:read',
  'dashboard.total_sales': 'dashboard:total-sales:read',
  'dashboard.transaction': 'dashboard:transaction:read',
  'expense.expense_details.add': 'expenses:expense-detail:add',
  'expense.expense_details.edit': 'expenses:expense-detail:edit',
  'expense.expense_list.add': 'expenses:expense-list:read',
  'expense.expense_list.search': 'expenses:expense-list:search',
  inventory: 'inventory::',
  'product.detail.read': 'inventory:product-detail:read',
  'product.detail.create': 'inventory:product-detail:create',
  'product.detail.update': 'inventory:product-detail:update',
  'product.detail.delete': 'inventory:product-detail:delete',
  'product.detail.print_barcode': 'inventory:product-detail:print-barcode',
  'product_details.quantity.update': 'inventory:product-detail:update-quantity',
  'product_details.average_cost.update':
    'inventory:product_detail:update-avg-cost',
  'product_details.quantity.read': 'inventory:product-detail:read-quantity',
  'product_details.retail_price.read':
    'inventory:product-detail:read-retail-price',
  'product_details.retail_price.update':
    'inventory:product-detail:update-retail-price',
  'product_details.wholesale_price.read':
    'inventory:product-detail:read-wholesale-price',
  'product_details.wholesale_price.update':
    'inventory:product-detail:update-wholesale-price',
  'product_details.buy_price.read': 'inventory:product-detail:read-buy-price',
  'product_details.buy_price.update':
    'inventory:product-detail:update-buy-price',
  'product_details.average_cost.read': 'inventory:product-detail:read-avg-cost',
  'product_details.supplier.create': 'inventory:product-detail:create-supplier',
  'product_details.supplier.read': 'inventory:product-detail:read-supplier',
  'product_details.supplier.update': 'inventory:product-detail:update-supplier',
  'product.list.read': 'inventory:product-list:read',
  'product.list.search': 'inventory:product-list:search',
  'product.list.export': 'inventory:product-list:export',
  'magento.dashboard.read': 'magento:dashboard:read',
  'magento.dashboard.update': 'magento:dashboard:update',
  'magento.error_list.read': 'magento:error-list:read',
  'magento.error_list.update': 'magento:error-list:update',
  'magento.product_management.read': 'magento:product-management:read',
  'magento.product_management.update': 'magento:product-management:update',
  'magento.setting.read': 'magento:setting:read',
  'magento.setting.update': 'magento:setting:update',
  'order.management': 'order-management::',
  'customer.detail.read': 'order-management:customer-detail:read',
  'customer.detail.create': 'order-management:customer-detail:create',
  'customer.detail.update': 'order-management:customer-detail:update',
  'customer.detail.delete': 'order-management:customer-detail:delete',
  'customer.list.read': 'order-management:customer-list:read',
  'customer.list.update': 'order-management:customer-list:update',
  'customer.list.search': 'order-management:customer-list:search',
  'customer.list.export': 'order-management:customer-list:export',
  'invoice.detail.read': 'order-management:invoice-detail:read',
  'invoice.detail.update': 'order-management:invoice-detail:update',
  'invoice.list.read': 'order-management:invoice-list:read',
  'invoice.list.update': 'order-management:invoice-list:update',
  'invoice.list.search': 'order-management:invoice-list:search',
  'invoice.list.export': 'order-management:invoice-list:export',
  'order.detail.read': 'order-management:order-detail:read',
  'order.detail.update': 'order-management:order-detail:update',
  'order.list.read': 'order-management:order-list:read',
  'order.list.update': 'order-management:order-list:update',
  'order.list.search': 'order-management:order-list:search',
  'order.list.export': 'order-management:order-list:export',
  'promotion.detail.create': 'promotions:promotion-detail:create',
  'promotion.detail.read': 'promotions:promotion-detail:read',
  'promotion.detail.update': 'promotions:promotion-detail:update',
  'promotion.detail.delete': 'promotions:promotion-detail:delete',
  'promotion.list.read': 'promotions:promotion-list:read',
  'promotion.list.search': 'promotions:promotion-list:search',
  'promotion.detail.print': 'promotions:promotion-detail:print',
  'qoyod.dashboard.read': 'qoyod:dashboard:read',
  'qoyod.dashboard.update': 'qoyod:dashboard:update',
  'qoyod.error_list.read': 'qoyod:error-list:read',
  'qoyod.error_list.update': 'qoyod:error-list:update',
  'qoyod.product_management.read': 'qoyod:product-management:read',
  'qoyod.product_management.update': 'qoyod:product-management:update',
  'qoyod.setting.read': 'qoyod:setting:read',
  'qoyod.setting.update': 'qoyod:setting:update',
  reports: 'reports::',
  'cost.change.read': 'reports:cost-change:read',
  'cost.change.export': 'reports:cost-change:export',
  'customer.debit.lifetime.read': 'reports:customer-debit-lifetime:read',
  'customer.debit.lifetime.export': 'reports:customer-debit-lifetime:read',
  'customer.movement.read': 'reports:customer-movement:read',
  'customer.movement.export': 'reports:customer-movement:export',
  'customers.products.read': 'reports:customer-products:read',
  'customers.products.export': 'reports:customer-products:export',
  'location.movement.read': 'reports:location-movement:read',
  'location.movement.export': 'reports:location-movement:export',
  'obsolete.inventory.read': 'reports:obsolete-inventory:read',
  'obsolete.inventory.export': 'reports:obsolete-inventory:update',
  'product.movement.read': 'reports:product-movement:read',
  'product.movement.export': 'reports:product-movement:export',
  'purchase.order.details.read': 'reports:purchase-order-details:read',
  'purchase.order.details.export': 'reports:purchase-order-details:export',
  'purchase.order.summary.read': 'reports:purchase-order-summary:read',
  'purchase.order.summary.export': 'reports:purchase-order-summary:export',
  'report.search': 'reports:reports-list:search',
  'sales.by.category.read': 'reports:sale-by-category:read',
  'sales.by.category.export': 'reports:sale-by-category:export',
  'sales.by.channel.read': 'reports:sale-by-channel:read',
  'sales.by.channel.export': 'reports:sale-by-channel:export',
  'sales.by.customer.read': 'reports:sale-by-customer:read',
  'sales.by.customer.export': 'reports:sale-by-customer:export',
  'sales.by.invoice.read': 'reports:sale-by-invoice:export',
  'sales.by.invoice.export': 'reports:sale-by-invoice:export',
  'sales.by.location.read': 'reports:sale-by-location:read',
  'sales.by.location.export': 'reports:sale-by-location:export',
  'sales.by.payment.method.read': 'reports:sale-by-payment-method:read',
  'sales.by.payment.method.export': 'reports:sale-by-payment-method:export',
  'sales.by.payment.status.read': 'reports:sale-by-payment-status:read',
  'sales.by.payment.status.export': 'reports:sale-by-payment-status:export',
  'sale.by.product.read': 'reports:sale-by-product:read',
  'sale.by.product.export': 'reports:sale-by-product:export',
  'sales.by.user.read': 'reports:sale-by-user:read',
  'sales.by.user.export': 'reports:sale-by-user:export',
  'stock.count.summary.read': 'reports:stock-count-summary:read',
  'stock.count.summary.export': 'reports:stock-count-summary:export',
  'supplier.credit.lifetime.read': 'reports:supplier-credit-lifetime:read',
  'supplier.credit.lifetime.export': 'reports:supplier-credit-lifetime:export',
  'supplier.debit.lifetime.read': 'reports:supplier-debit-lifetime:read',
  'supplier.debit.lifetime.export': 'reports:supplier-debit-lifetime:export',
  'supplier.movement.read': 'reports:supplier-movement:read',
  'supplier.movement.export': 'reports:supplier-movement:export',
  'supplier.summary.read': 'reports:supplier-summary:read',
  'supplier.summary.export': 'reports:supplier-summary:export',
  'tax.declaration.read': 'reports:tax-declaration:read',
  'tax.declaration.export': 'reports:tax-declaration:export',
  'tracking.information.read': 'reports:tracking-information:read',
  'tracking.information.export': 'reports:tracking-information:export',
  'salla.dashboard.read': 'salla:dashboard:read',
  'salla.dashboard.update': 'salla:dashboard:update',
  'salla.error_list.read': 'salla:error-list:read',
  'salla.error_list.update': 'salla:error-list:update',
  'salla.product_management.read': 'salla:product-management:read',
  'salla.product_management.update': 'salla:product-management:update',
  'salla.setting.read': 'salla:setting:read',
  'salla.setting.update': 'salla:setting:update',
  setting: 'settings::',
  'company.overview.read': 'settings:company-overview:read',
  'company.overview.update': 'settings:company-overview:update',
  'custom_field.read': 'settings:custom-field:read',
  'custom_field.add': 'settings:custom-field:create',
  'custom_field.edit': 'settings:custom-field:update',
  'custom_field.delete': 'settings:custom-field:delete',
  'location.detail.read': 'settings:location-detail:read',
  'location.detail.create': 'settings:location-detail:create',
  'location.detail.update': 'settings:location-detail:update',
  'location.detail.delete': 'settings:location-detail:delete',
  'location.list.search': 'settings:location-list:search',
  'location.list.export': 'settings:location-list:export',
  'payment.method.detail.create': 'settings:payment-method-detail:create',
  'payment.method.detail.read': 'settings:payment-method-detail:read',
  'payment.method.detail.update': 'settings:payment-method-detail:update',
  'payment.method.list.read': 'settings:payment-method-list:read',
  'subscription.read': 'settings:subscription:read',
  'subscription.update': 'settings:subscription:update',
  'tax.detail.read': 'settings:tax-detail:read',
  'tax.detail.create': 'settings:tax-detail:create',
  'tax.detail.update': 'settings:tax-detail:update',
  'tax.list.read': 'settings:tax-list:read',
  'user.detail.read': 'settings:user-detail:read',
  'user.detail.create': 'settings:user-detail:create',
  'user.detail.update': 'settings:user-detail:update',
  'user.detail.delete': 'settings:user-detail:delete',
  'location.list.read': 'settings:location-list:read',
  'user.list.read': 'settings:user-list:read',
  'user.list.search': 'settings:user-list:search',
  'user.list.export': 'settings:user-list:export',
  'location.list.update': 'settings:location-list:update',
  'location.list.delete': 'settings:location-list:delete',
  'stock.control': 'stock-control::',
  'payment.detail.read': 'stock-control:payment-detail:read',
  'payment.list.read': 'stock-control:payment-list:read',
  'payment.list.search': 'stock-control:payment-list:search',
  'payment.list.export': 'stock-control:payment-list:export',
  'purchase.order.detail.read': 'stock-control:purchase-order-detail:read',
  'purchase.order.detail.create': 'stock-control:purchase-order-detail:create',
  'purchase.order.detail.update': 'stock-control:purchase-order-detail:update',
  'purchase.order.list.read': 'stock-control:purchase-order-list:read',
  'purchase.order.list.search': 'stock-control:purchase-order-list:search',
  'purchase.order.list.export': 'stock-control:purchase-order-list:export',
  'remove.stock.detail.read': 'stock-control:remove-stock-detail:read',
  'remove.stock.detail.create': 'stock-control:remove-stock-detail:create',
  'remove.stock.detail.update': 'stock-control:remove-stock-detail:update',
  'remove.stock.list.read': 'stock-control:remove-stock-list:read',
  'remove.stock.list.search': 'stock-control:remove-stock-list:search',
  'remove.stock.list.export': 'stock-control:remove-stock-list:export',
  'return.stock.detail.read': 'stock-control:return-stock-detail:read',
  'return.stock.detail.create': 'stock-control:return-stock-detail:create',
  'return.stock.detail.update': 'stock-control:return-stock-detail:update',
  'return.stock.list.read': 'stock-control:return-stock-list:read',
  'return.stock.list.search': 'stock-control:return-stock-list:search',
  'return.stock.list.export': 'stock-control:return-stock-list:export',
  'stock.count.detail.read': 'stock-control:stock-count-detail:read',
  'stock.count.detail.create': 'stock-control:stock-count-detail:create',
  'stock.count.detail.update': 'stock-control:stock-count-detail:update',
  'stock.count.detail.view_cost': 'stock-control:stock-count-detail:view-cost',
  'stock.count.list.read': 'stock-control:stock-count-list:read',
  'stock.count.list.search': 'stock-control:stock-count-list:search',
  'stock.count.list.export': 'stock-control:stock-count-list:export',
  'stock.count.list.view_cost': 'stock-control:stock-count-list:view-cost',
  'supplier.detail.read': 'stock-control:supplier-detail:read',
  'supplier.detail.create': 'stock-control:supplier-detail:create',
  'supplier.detail.update': 'stock-control:supplier-detail:update',
  'supplier.list.read': 'stock-control:supplier-list:read',
  'supplier.list.search': 'stock-control:supplier-list:search',
  'supplier.list.export': 'stock-control:supplier-list:export',
  'transfer.multiple.stock.detail.create':
    'stock-control:transfer-stock:multiple-create',
  'transfer.stock.detail.read': 'stock-control:transfer-stock-detail:read',
  'transfer.stock.detail.create': 'stock-control:transfer-stock-detail:create',
  'transfer.stock.detail.update': 'stock-control:transfer-stock-detail:update',
  'transfer.stock.detail.view_cost':
    'stock-control:transfer-stock-detail:view-cost',
  'transfer.stock.list.read': 'stock-control:transfer-stock-list:read',
  'transfer.stock.list.search': 'stock-control:transfer-stock-list:search',
  'transfer.stock.list.export': 'stock-control:transfer-stock-list:export',
  'woo_commerce.dashboard.read': 'woo-commerce:dashboard:read',
  'woo_commerce.dashboard.update': 'woo-commerce:dashboard:read',
  'woo_commerce.error_list.read': 'woo-commerce:error-list:read',
  'woo_commerce.error_list.update': 'woo-commerce:error-list:update',
  'woo_commerce.product_management.read':
    'woo-commerce:product-management:read',
  'woo_commerce.product_management.update':
    'woo-commerce:product-management:update',
  'woo_commerce.setting.read': 'woo-commerce:setting:read',
  'woo_commerce.setting.update': 'woo-commerce:setting:update',
  'zatca.dashboard.read': 'zatca:dashboard:read',
  'zatca.dashboard.update': 'zatca:dashboard:update',
  'zatca.error_list.read': 'zatca:error-list:read',
  'zatca.settings.read': 'zatca:settings:read',
  'zid.dashboard.read': 'zid:dashboard:read',
  'zid.dashboard.update': 'zid:dashboard:update',
  'zid.error_list.read': 'zid:error-list:read',
  'zid.error_list.update': 'zid:error-list:update',
  'zid.product_management.read': 'zid:product-management:read',
  'zid.product_management.update': 'zid:product-management:update',
  'zid.setting.read': 'zid:setting:read',
  'zid.setting.update': 'zid:setting:update',
  'product.list.update': 'inventory:product-list:update',
  'user.list.update': 'settings:user-list:update',
  'purchase.order.read': 'stock-control:purchase-order:read', // should be updated
  'purchase.order.export': 'stock-control:purchase-order:export', // should be updated
  'dashboard.search': 'dashboard::search',
  'report.read': 'reports:reports-list:read',
  'product.list.delete': 'inventory:product-list:delete',
  'batch.list.read': 'inventory:batch-list:read',
  'batch.list.create': 'inventory:batch-list:create',
  'batch.list.update': 'inventory:batch-list:update',
  'batch.list.delete': 'inventory:batch-list:delete',
  'batch.list.search': 'inventory:batch-list:search',
  'batch.list.export': 'inventory:batch-list:export',
  'product.detail.search': 'inventory:product-detail:search',
  'product.detail.export': 'inventory:product-detail:export',
  'supplier.list.update': 'stock-control:supplier-list:update',
  'user.list.delete': 'settings:user-list:delete',
  'tax.list.create': 'settings:tax-list:create',
  'tax.list.update': 'settings:tax-list:update',
  'configuration.list.read': 'settings:configuration-list:read',
  'configuration.list.update': 'settings:configuration-list:update',
  'configuration.detail.read': 'settings:configuration-detail:read',
  'configuration.detail.update': 'settings:configuration-detail:update',
  'available.app.read': 'applications:available-app:read',
  'available.app.update': 'applications:available-app:update',
  'promotion.list.create': 'promotions:promotion-list:create',
  'promotion.list.update': 'promotions:promotion-list:update',
  'promotion.list.delete': 'promotions:promotion-list:delete',
  'promotion.list.print': 'promotions:promotion-list:print',
  'promotion.detail.search': 'promotions:promotion-detail:search',
  'stock.value.export': 'reports:stock-value:export',
  'stock.value.read': 'reports:stock-value:read',
  'variation.read': 'reports:variation:read',
  'variation.export': 'reports:variation:export',
  'expense.expense_list.edit': 'expenses:expense-list:edit',
  'expense.expense_list.delete': 'expenses:expense-list:delete',
  'available.quantity.read': 'reports:available-quantity:read',
  'available.quantity.export': 'reports:available-quantity:export',
  'order.detail.issue_invoice': 'order-management:order-detail:issue-invoice',
  'order.settings.read': 'order-management:order-settings:read',
  'order.settings.update': 'order-management:order-settings:update',
  'daily.sales.read': 'sales-reports:daily-sales:read',
  'daily.sales.export': 'sales-reports:daily-sales:export',
  'product.available_quantity.read':
    'inventory:product-list:read-available-quantity',
  'product.sold_quantity.read': 'inventory:product-list:read-sold-quantity',
  'product.retail_price.read': 'inventory:product-list:read-retail-price',
  'product.wholesale_price.read': 'inventory:product-list:read-wholesale-price',
  'product.buy_price.read': 'inventory:product-list:read-buy-price',
  'product.cost.read': 'inventory:product-list:read-product-cost',
  'product.supplier.read': 'inventory:product-list:read-product-supplier',
  'pos.sales_screen.open': 'pos:register:open',
  'pos.sales_screen.self_close_opened': 'pos:register:close-opened',
  'pos.sales_screen.close_all': 'pos:register:close-all',
  'pos.sales_screen.sell': 'pos:sales-screen:sell',
  'pos.sales_screen.return': 'pos:sales-screen:return',
  'pos.sales_screen.add_&_withdraw': 'pos:sales-screen:add-and-withdraw',
  'pos.sales_screen.park_/_retrieve_sale': 'pos:sales-screen:park-and-retrieve',
  'pos.sales_screen.view_all_location_invoices':
    'pos:sales-screen:view-all-location-invoices',
  'pos.cash_management.read': 'pos:cash-management:read',
  'pos.cash_management.export': 'pos:cash-management:export',
  'pos.receipt_setting.read': 'pos:receipt-setting:read',
  'pos.receipt_setting.update': 'pos:receipt-setting:update',
  'pos.sales_setting.read': 'pos:sales-setting:read',
  'pos.sales_setting.update': 'pos:sales-setting:update',
  'point_of_sale.dashboard.read': 'pos:dashboard:read',
  'point_of_sale.dashboard.update': 'pos:dashboard:update',
  'point_of_sale.product_management.read': 'pos:product-management:read',
  'point_of_sale.product_management.update': 'pos:product-management:update',
  'point_of_sale.setting.read': 'pos:setting:read',
  'point_of_sale.setting.update': 'pos:setting:update',
  'point_of_sale.error_list.read': 'pos:error-list:read',
  'point_of_sale.error_list.update': 'pos:error-list:update',
  'pos.sales_screen.product_delete': 'pos:sales-screen:product-delete',
  'pos.sales_screen.edit_price_/_discount':
    'pos:sales-screen:edit-price-and-discount',
  'pos.payment.post_payment': 'pos:payment:post',
  'pos.layout_setting.read': 'pos:layout-setting:read',
  'pos.layout_setting.update': 'pos:layout-setting:update',
  'pos.layout_setting.delete': 'pos:layout-setting:delete',
  'pos.layout_setting.search': 'pos:layout-setting:search',
  'pos.sell_at_wholesale.read': 'pos:sell-at-wholesale:read',
  'pos.sell_at_wholesale.update': 'pos:sell-at-wholesale:update',
  'pos.product_details.product_details': 'pos:product-details:root',
  'pos.product_details.quantity': 'pos:product-details:quantity',
  'pos.product_details.cost': 'pos:product-details:cost',
  'pos.product_details.view_all_location_details':
    'pos:product-details:view-all-location-details',
};
