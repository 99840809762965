export enum SummaryPeriod {
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export enum SummaryDetailPeriod {
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
}

export enum SummaryDetailPeriodTranslateKey {
  'week' = 'weekWithNumber',
  'month' = 'monthWithNumber',
  'quarter' = 'quarterWithNumber',
}

/**
 * @deprecated Use AccountTransactionType in rewaa-accounting package
 */
export enum AccountTransactionType {
  Debit = 'Debit',
  Credit = 'Credit',
}

export enum AccountHeadURI {
  CashAndCashEqv = 'cash-cash-eqv',
  OpeningBalance = 'opening-balance',
}

/**
 * @deprecated Use TransactionType available in rewaa-accounting package
 */
export enum TransactionTypes {
  POSSell = 'POSSell',
  POSReturn = 'POSReturn',
  OnlineOrderSell = 'OnlineOrderSell',
  OnlineOrderReturn = 'OnlineOrderReturn',
  WalletTransaction = 'WalletTransaction',
  ExpenseCreation = 'ExpenseCreation',
  ExpenseUpdate = 'ExpenseUpdate',
  ExpenseReverseViaUpdate = 'ExpenseReverseViaUpdate',
  PurchaseOrder = 'PurchaseOrder',
  SupplierPayCredit = 'SupplierPayCredit',
  ExpenseDeletion = 'ExpenseDeletion',
  CustomerReceiveDebit = 'CustomerReceiveDebit',
  ReturnStock = 'ReturnStock',
  SupplierReceiveDebit = 'SupplierReceiveDebit',
  RegisterOperation = 'RegisterOperation',
  AddProduct = 'AddProduct',
  DeleteProduct = 'DeleteProduct',
  EditProduct = 'EditProduct',
  StockCount = 'StockCount',
  RemoveStock = 'RemoveStock',
  JournalEntry = 'JournalEntry',
  BalanceTermination = 'BalanceTermination',
  OpeningBalance = 'OpeningBalance',
  AdjustmentJournalEntry = 'AdjustmentJournalEntry',
  ClosingFiscalPeriod = 'ClosingFiscalPeriod',
  StockCountCompleted = 'StockCountCompleted',
  OpeningBalanceJournalEntry = 'OpeningBalanceJournalEntry',
  TransferStock = 'TransferStock',
  ImportProducts = 'ImportProducts',
}

export enum CurrencyCodes {
  SAR = 'SAR',
}

export enum UpdateWallet {
  Add = 'Add',
  Withdraw = 'Withdraw',
}

/**
 * @deprecated Use AccountTransactionType in rewaa-accounting package
 */
export enum AccountingGroupEnum {
  Credit = 'Credit',
  Debit = 'Debit',
}

export const removeVatTaxByDivideThis = 1.15;
