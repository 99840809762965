export const CashActionConstant = {
  Add: 'Add',
  Withdraw: 'Withdraw',
} as const;
export type CashAction =
  (typeof CashActionConstant)[keyof typeof CashActionConstant];

export const RegisterStatusConstant = {
  Opened: 'Opened',
  Closed: 'Closed',
  Inactive: 'Inactive',
} as const;
export type RegisterStatus =
  (typeof RegisterStatusConstant)[keyof typeof RegisterStatusConstant];

export const DataPermissionTypeConstant = {
  Location: 'Location',
} as const;
export type DataPermissionType =
  (typeof DataPermissionTypeConstant)[keyof typeof DataPermissionTypeConstant];

export const TemplateTypeConstant = {
  Sales: 'Sales/ Return',
  KitchenTicket: 'Kitchen ticket',
} as const;
export type TemplateType =
  (typeof TemplateTypeConstant)[keyof typeof TemplateTypeConstant];

export const ShiftStatusConstant = {
  Opened: 'Opened',
  Closed: 'Closed',
} as const;

export type ShiftStatus =
  (typeof ShiftStatusConstant)[keyof typeof ShiftStatusConstant];

export const ShiftActionTypeConstant = {
  Open: 'Open',
  Close: 'Close',
  Add: 'Add',
  Withdraw: 'Withdraw',
} as const;

export type ShiftActionLogType =
  (typeof ShiftActionTypeConstant)[keyof typeof ShiftActionTypeConstant];

export const LayoutTypeConstant = {
  Classic: 'Classic',
  Customized: 'Customized',
} as const;

export type LayoutType =
  (typeof LayoutTypeConstant)[keyof typeof LayoutTypeConstant];

export const ErrorCodeConstant = {
  RegisterNotUnique: 'register-not-unique',
  LocationUnAuthorizedAccess: 'location-un-authorized',
  LayoutNotFounds: 'layout-not-found',
  RegisterNotFound: 'register-not-found',
  UpdateOpenedRegister: 'update-opened-register',
  DeleteDefaultRegister: 'delete-default-register',
  DeleteOpenRegister: 'delete-open-register',
  DeactivateOpenRegister: 'deactivate-open-register',
  DeactivateDefaultRegister: 'deactivate-default-register',
  ActivateActiveRegister: 'activate-active-register',
  OpenInactiveRegister: 'open-inactive-register',
  OpenAnOpenedRegister: 'open-an-opened-register',
  ShiftNotOpened: 'shift-not-opened',
  InvalidOpeningAmount: 'invalid-opening-amount',
  RegisterMustBeClosed: 'register-must-be-closed',
  DefaultRegisterMustBeClosed: 'default-register-must-be-closed',
  InvalidPaymentMethod: 'invalid-payment-method',
  InactiveRegister: 'inactive-register',
  RegisterAlreadyClosed: 'register-already-closed',
  InvalidWithdrawAmount: 'invalid-withdraw-amount',
  ShiftNotFound: 'shift-not-found',
  CashManagementPaymentMethodNotFound:
    'cash-management-payment-method-not-found',
  ShiftActionLogNotFound: 'shift-action-log-not-found',
  RegisterReportCapacityExceeded: 'register-report-capacity-exceeded',
  InvalidShiftId: 'invalid-shift-id',
};

export type ErrorCode =
  (typeof ErrorCodeConstant)[keyof typeof ErrorCodeConstant];

export const PaymentMethodTypeConstant = {
  Cash: 'Cash',
  Card: 'Card',
  Other: 'Other',
  SupplierDebit: 'SupplierDebit',
  CustomerDebit: 'CustomerDebit',
  SoftPos: 'SoftPos',
} as const;

export type PaymentMethodType =
  (typeof PaymentMethodTypeConstant)[keyof typeof PaymentMethodTypeConstant];

export const CashManagementShiftStatusConstant = {
  Open: 'Open',
  Balanced: 'Balanced',
  Unbalanced: 'Unbalanced',
} as const;

export type CashManagementShiftStatus =
  (typeof CashManagementShiftStatusConstant)[keyof typeof CashManagementShiftStatusConstant];
