<div class="container pt-4 px-1 bulk-delete-container" [ngClass]="langCss">
    <div *ngIf="!dialog">
        <button pendo-tracker="dbpb" type="button" class="close-button" (click)=content.cancelFunction()><i class="pi pi-times"></i></button>
    </div>
    <div class="header px-4">
        <div class="title">
            <span>{{ 'Delete' | translate }} {{ selectedProducts.length }} {{ 'Products' | translate}}</span>
        </div>
        <div class="info-wrapper">
            <span>{{ 'Deleting Products will affect POS, Stock control, Order integrations, and Inventory' | translate }}</span>
        </div>
    </div>
    <div class="note-msg px-4 mt-1">{{ 'Deleting a variant product or pack product will delete the Main product' | translate }}</div>
    <div class="px-4 mb-4">
        <div class="que-wrapper mt-4">
            <span>{{ 'Are you sure you want to delete the selected products?' | translate }}</span>
        </div>
        <div class="products-list my-2">
            <div *ngFor="let product of products let i = index">
                <span>
                    <p-checkbox [value]="product" name="products" [(ngModel)]="selectedProducts"></p-checkbox>
                </span>
                <span class="product-name">
                    {{ product.name }}
                </span>
                <div *ngIf="i<products.length-1" class="line my-2"></div>
            </div>
        </div>
    </div>
    <div class="position-sticky bottom-0 flex align-items-start button-container py-3">
        <button pendo-tracker="dbpb-1" pButton class="btn text-capitalize pt-3 pb-3 pr-5 pl-5 ml-3 mr-3 p-button-text" (click)="content.cancelFunction()">{{ 'Cancel' | translate }}</button>
        <button pendo-tracker="dbpb-2" pButton class="btn text-capitalize pt-3 pb-3 pr-5 pl-5 ml-3 mr-3 p-button-text delete-btn" (click)=onDelete()>{{ 'Delete' | translate }} {{ selectedProducts.length }} {{ 'Products' | translate }}</button>
    </div>
</div>
