<rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner>
<div id="rw__auth-page" class="auth-page  min-h-screen md:flex w-full align-items-center justify-content-center flex-row-reverse" [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
  <rw-side-panel></rw-side-panel>

  <div class="auth-form-area bg-white flex align-items-start  justify-content-center w-full mx-0 md:mx-3">
    <div class="py-5 px-3">
      <div class="flex align-items-start justify-content-between gap-2 md:gap-5 pb-5">
        <img class="max-w-full h-auto d-block auth-logo" src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="">
        <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
      </div>
      <form
        [formGroup]="fGroup"
        #ngForm
        (ngSubmit)="register(fGroup.value)"
        class="p-fluid grid auth-form"
      >
        <div class="field col-12 rw__pb-0">
          <label class="rw__fs-6 fw-500 rw__mb-0">{{ 'auth.createNewAccount' | translate }}</label>
        </div>
        <!-- EMAIL INPUT -->
        <div class="field col-12" [ngClass]="changeLang !== 'ar' ? 'rw__input-text-end' : ''">
          <rw-input [required]="true" name="email" [label]="'Email Address' | translate" [control]="fGroup.get('email')" context="register"></rw-input>
          <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
            <div class="flex align-items-center mt-1">
              <i class="fa-regular fa-circle-exclamation p-error"></i>
              <small *ngIf="email.errors.required" class="p-error block mx-1">{{ 'Email is required' | translate }}</small>
              <small *ngIf="email.errors.pattern" class="p-error block mx-1">{{ 'Not valid email' | translate }}</small>
            </div>
          </ng-container>
        </div>

        <!-- COMPANY NAME INPUT -->
        <div class="field col-12" [ngClass]="changeLang !== 'ar' ? 'rw__input-text-end' : ''">
          <rw-input [required]="true" name="companyName" [label]="'Company' | translate" [control]="fGroup.get('companyName')" context="register"></rw-input>
          <ng-container *ngIf="companyName.invalid && (companyName.dirty || companyName.touched)">
            <div class="flex align-items-center mt-1 font-light">
              <i class="fa-regular fa-circle-exclamation p-error"></i>
              <small *ngIf="companyName.errors.required" class="p-error block mx-1">{{ 'cRequired' | translate }}</small>
            </div>
          </ng-container>
       </div>

        <!-- PHONE NUMBER INPUT -->
        <div class="field col-12">
          <div class="d-flex" [ngClass]="changeLang !== 'ar' ? 'flex-row-reverse' : ''">
            <span id="rw__input-country" class="rw__input rw__input-float p-float-label minw-0" [ngClass]="changeLang !== 'ar' ? 'rw__ms-3' : 'rw__me-3'"
                  pendo-tracker="create_new_account_country_code_drop_down_clicked"
            >
              <p-dropdown panelStyleClass="rw__input-country-dropdown" [options]="countries" [(ngModel)]="selectedCountry" optionLabel="name" [filter]="true" filterBy="localeName" [autoDisplayFirst]="false"
                          (onChange)="onCountrySelect()" [ngModelOptions]="{standalone: true}" appendTo="body">
                <ng-template pTemplate="filter" let-options="options">
                  <div class="d-flex">
                    <span class="rw__input rw__input-default rw__input-icon rw__input-icon-left w-full" (click)="$event.stopPropagation()" [ngClass]="{'rw__input-icon-close': filterValue}">
                      <i class="fa-regular fa-magnifying-glass"></i>
                      <input type="text" pInputText placeholder="{{ 'Search' | translate }}" [(ngModel)]="filterValue" [ngModelOptions]="{standalone: true}" (keyup)="options.filter($event)">
                      <i class="fa-regular fa-xmark" (click)="myResetFunction(options)"></i>
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="selectedItem">
                    <div class="country-item country-item-value" *ngIf="selectedCountry">
                      <img src="https://flagsapi.com/{{selectedCountry.countryKey}}/flat/32.png" alt="{{ selectedCountry.name }} Flag" />
                    </div>
                </ng-template>
                <ng-template let-country pTemplate="item">
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <span>{{ country.localeName }}</span>
                          <span class="rw__ms-2 ltr">(+{{country.code}})</span>
                        </div>
                    </div>
                </ng-template>
              </p-dropdown>
            </span>

            <span class="rw__input rw__input-float rw__input-prefix p-float-label w-full" [ngClass]="{'rw__country-selected': selectedCountry}">
              <span *ngIf="selectedCountry" class="rw__input-prefix-addon" data-sign="+">
                {{selectedCountry.code}}
              </span>
              <input id="payment-phone-input" type="text" pInputText placeholder="XXX XXX XXX" [formControl]="fGroup.get('phone')" autocomplete="one-time-code"
                     pendo-tracker="create_new_account_phone_number_field_typing"/>
              <label for="payment-phone-input">
                <i class="text-danger">*</i>
                {{ 'auth.phoneNumberTitle' | translate }}
              </label>
            </span>
          </div>
          <ng-container *ngIf="phone.invalid && (phone.dirty || phone.touched)">
            <div class="d-flex flex-column">
              <div *ngIf="phone.errors.required" class="d-flex rw__mt-2">
                <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.phoneRequired' | translate }}</small>
              </div>
              <div *ngIf="phone.errors.zeroSaudiPattern" class="d-flex rw__mt-2">
                <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.zeroSaudiPattern' | translate }}</small>
              </div>
              <div *ngIf="phone.errors.saudiPattern" class="d-flex rw__mt-2">
                <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.saudiPattern' | translate }}</small>
              </div>
              <div *ngIf="phone.errors.saudiZeroOrZeroFive" class="d-flex rw__mt-2">
                <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.saudiZeroOrZeroFive' | translate }}</small>
              </div>
              <div *ngIf="phone.errors.otherThanSaudiPattern" class="d-flex rw__mt-2">
                <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
                <small class="p-error block mx-1">{{ 'auth.phoneErrorNotSaudiPattern' | translate }}</small>
              </div>

            </div>
          </ng-container>
        </div>

        <div class="field col-12">
          <!-- PASSWORD INPUT -->
          <span class="rw__input rw__input-float p-float-label w-full">
            <p-password [formControl]="fGroup.get('password')" [(ngModel)]="passwordModel" [toggleMask]="true" [feedback]="false" [showClear]="true" required></p-password>
            <label for="password-input">
              <i class="text-danger">*</i>
              {{ 'auth.passwordTitle' | translate }}
            </label>
          </span>
          <ng-container *ngIf="password.invalid && (password.dirty || password.touched)">
            <div class="flex align-items-start mt-1">
              <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
              <small *ngIf="password.errors.required" class="p-error block mx-1">{{ 'Password is required' | translate }}</small>
              <small *ngIf="password.errors.pattern" class="p-error block mx-1">{{ 'passwordValidation.requirements' | translate }}</small>
            </div>
          </ng-container>
        </div>

        <div class="field col-12 rw__mt-4">
          <div class="flex flex-column">
            <div class="d-flex align-items-center justify-content-between rw__mb-3">
              <span (click)="handleShowLicenseKey()" class="show-license-key">{{'auth.haveLicenseKey'|translate}}</span>
              <div>
                <button type="button" (click)="openLicenseKeyDialog()" class="rw__fs-9 text-grey-60 bg-transparent border-0 rw__p-0">
                  {{ 'auth.whatIsLicenseKey' | translate }}
                </button>
              </div>
            </div>
            <span *ngIf="showLicenseKey" class="rw__input rw__input-default" [ngClass]="lang === 'ar' ? 'rw__input-mask' : ''">
              <p-inputMask  mask="99999 - 99999 - 99999 - 99999" placeholder="XXXXX - XXXXX - XXXXX - XXXXX" slotChar="x"
                [unmask]="true" [autoClear]="false" [formControl]="fGroup.get('licenseKey')" [showClear]="true" pendo-tracker="create_new_account_license_key_field_typing"
                (onComplete)="onLicenseKeyComplete()" (keyup)="validateLicenseKeyInput()" (onBlur)="validateLicenseKeyInput()" (onClear)="clearLicenseKey()">
                <input type="text" pInputText/>
              </p-inputMask>
              <ng-container *ngIf="licenseKey.touched && licenseKey.invalid && (licenseKey.dirty || licenseKey.touched)">
                <div class="flex align-items-center mt-1">
                  <i class="fa-regular fa-circle-exclamation p-error"></i>
                  <small *ngIf="licenseKey.errors.minlength || licenseKey.errors.maxlength" class="p-error block mx-1">
                    {{ 'auth.licenseLengthError' | translate }}
                  </small>
                  <small *ngIf="licenseKey.errors.notValid" class="p-error block mx-1">
                    {{ 'auth.licenseNotValidError' | translate }}
                  </small>
                </div>
              </ng-container>
              <small class="rw__fs-10 text-grey-50 block rw__mt-2">
                {{ 'auth.youCanFindLicenseKeyMessage' | translate }}
              </small>
            </span>
          </div>
        </div>

        <div class="field col-12 rw__mb-4 text-center">
          <button
            pButton pRipple
            pendo-tracker="create_new_account_register_cta_button_click"
            type="submit"
            class="login-button rw__fs-8"
            [disabled]="isShowSpinner || fGroup.invalid"
            label="{{ 'auth.createNewAccount' | translate }}"></button>
        </div>

        <div class="field col-12 mb-0 text-center">
          <span class="rw__fs-8 text-grey-60">
            {{ 'auth.alreadyHaveAccount' | translate }}
            <a [routerLink]="['/login']" class="rw__link" pendo-tracker="create_new_account_login_cta_button_click">{{ 'Login' | translate }}</a>
          </span>
        </div>
      </form>
    </div>
  </div>



</div>

<!-- License Key Modal - Start -->
<rw-license-key [dialogVisible]="openLicenseKeyDialogVisible" (closeDialog)="closeLicenseKeyDialog()"></rw-license-key>
<!-- License Key Modal - End -->
