import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TemplateVersion } from 'src/app/internal-apps/pos/utils/constants';
import { mapTemplateVersionOneToTwo } from 'src/app/internal-apps/pos/utils/common.utils';
import { IOrderSerachFilter } from '../../../shared/model/order/order-search-and-filter';
import { Invoice } from '../../../shared/model/invoice/Invoice';
import { IssueInvoiceResponse } from '../../models/issue-invoice';
import {CommonEnums, InvoiceEnums } from '@rewaa-team/pos-sdk';
import { AppToSource } from '../../../shared/constants';

const API_URL = '/api';

@Injectable()
export class OrderInvoiceService {
  constructor(private http: HttpClient) {}

  getAllOrderInvoice(): Observable<any> {
    return this.http.get<any>(`${API_URL}/orders/invoices`);
  }

  getOrderInvoiceById(orderInvoiceId): Observable<any> {
    return this.http
      .get<any>(`${API_URL}/orders/invoices/${orderInvoiceId}`)
      .pipe(
        tap((payload) => {
          if (
            payload.settings &&
            payload.templateVersionNumber === TemplateVersion.ONE
          ) {
            payload.settings = JSON.parse(payload.settings);
            payload.settings = mapTemplateVersionOneToTwo(payload.settings);
            payload.settings = JSON.stringify(payload.settings);
          }
        }),
      );
  }

  getIssueInvoiceByIds(orderIds: string): Observable<IssueInvoiceResponse> {
    const orderQueryParams = new HttpParams().set('orderIds', orderIds);
    return this.http.post<IssueInvoiceResponse>(
      `${API_URL}/orders/issueInvoice`,
      null,
      {
        params: orderQueryParams,
      },
    );
  }

  createReturnInvoice(orderId): any {
    return this.http.post<any>(
      `${API_URL}/orders/returnInvoice/${orderId}`,
      null,
    );
  }

  exportInvoices(query: IOrderSerachFilter): Observable<any> {
    const timeZoneOffset = (new Date().getTimezoneOffset() / 60) * -1;
    const orderQueryParams = new HttpParams()
      .set('search', query.search || '')
      .set('timeZoneOffset', timeZoneOffset.toString())
      .set('app', query.channel || '')
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toUTCString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate
          ? query.dates.toDate.toUTCString()
          : '',
      )
      .set('location', query.location || '')
      .set('sellType', query.sellType || '')
      .set('invoiceStatus', query.invoiceStatus || '')
      .set('customerId', query.customerId || '')
      .set('payment', query.payment || '');
    return this.http.get<any>(`${API_URL}/orders/invoices/export`, {
      params: orderQueryParams,
    });
  }

  getPosQueryInvoicesList(
    query: IOrderSerachFilter,
  ): Observable<{ result: Invoice[]; total: number }> {
    let orderQueryParams = new HttpParams()
      .set('limit', query.limit)
      .set('offset', query.offset);

    if (query.search)
      orderQueryParams = orderQueryParams.set('invoiceNumber', query.search);

    if (query.dates?.fromDate)
      orderQueryParams = orderQueryParams.set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toISOString()
          : '',
      );
    if (query.dates?.toDate)
      orderQueryParams = orderQueryParams.set(
        'toDate',
        query.dates && query.dates.toDate
          ? query.dates.toDate.toISOString()
          : '',
      );

    if (query.location)
      orderQueryParams = orderQueryParams.set('locationIds', query.location);
    if (query.sellType) {
      orderQueryParams = orderQueryParams.set(
        'type',
        query.sellType === 'SELL' ? 'POSSale' : 'POSReturn',
      );
    }

    let status: string;
    switch (query.invoiceStatus) {
      case 'Active':
        status = InvoiceEnums.InvoiceStatusConstant.Completed;
        break;
      case 'Completed':
        status = InvoiceEnums.InvoiceStatusConstant.Completed;
        break;
      case 'Void':
        status = InvoiceEnums.InvoiceStatusConstant.Rejected;
        break;
      case 'Open':
        status = InvoiceEnums.InvoiceStatusConstant.Pending;
        break;
      default:
        status = InvoiceEnums.InvoiceStatusConstant.Completed;
    }

    orderQueryParams = orderQueryParams.set('status', status);

    if (query.channel) {
      orderQueryParams = orderQueryParams.set(
        'source',
        AppToSource[query.channel],
      );
    }

    if (query.payment)
      orderQueryParams = orderQueryParams.set('paymentIds', query.payment);

    orderQueryParams.set('withCustomer', 'true');
    if(query.customerId) {
      orderQueryParams = orderQueryParams.set('customerId', query.customerId);
    }

    if (query.shiftId) {
      orderQueryParams = orderQueryParams.set('shiftId', query.shiftId);
    }
    
    return this.http
      .get<{ data: Invoice[]; meta: { total: number } }>(
        `${API_URL}/enigma/invoices`,
        { params: orderQueryParams },
      )
      .pipe(
        map((data) => {
          return {
            result: data.data,
            total: data.meta.total,
          };
        }),
      );
  }

  getQueryInvoicesList(
    query: IOrderSerachFilter,
    isPosReborn?: boolean,
  ): Observable<{ result: Invoice[]; total: number }> {
    if (isPosReborn) return this.getPosQueryInvoicesList(query);

    const orderQueryParams = new HttpParams()
      .set('search', query.search || '')
      .set('app', query.channel || '')
      .set(
        'fromDate',
        query.dates && query.dates.fromDate
          ? query.dates.fromDate.toUTCString()
          : '',
      )
      .set(
        'toDate',
        query.dates && query.dates.toDate
          ? query.dates.toDate.toUTCString()
          : '',
      )
      .set('locationId', query.location || '')
      .set('sellType', query.sellType || '')
      .set('invoiceStatus', query.invoiceStatus || '')
      .set('customerId', query.customerId || undefined)
      .set('offset', query.offset || '0')
      .set('payment', query.payment || '')
      .set('limit', query.limit || '10');

    return this.http.get<{ result: Invoice[]; total: number }>(
      `${API_URL}/orders/invoices`,
      { params: orderQueryParams },
    );
  }
}
