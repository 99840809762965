import { formatDate } from '@angular/common';
import {
  CustomerTypes,
  InvoiceEnums,
  InvoiceTypes,
  OfflinePosTypes,
  commonCalculationService,
} from '@rewaa-team/pos-sdk';
import { InvoiceSnapshotTypes } from '@rewaa-team/types';
import JSBarcode from 'jsbarcode';
import Qrious from 'qrious';
import { firstValueFrom } from 'rxjs';
import { convertBlobToBase64 } from '../../internal-apps/pos/utils/sell.utils';
import { ZatcaTransactionStatus } from '../../shared/model/invoice/Invoice';
import { Denomination } from '../types/register.type';
import { Customer } from '../../shared/model/order/Customer';

const getTLV = (tag: number, value: string) => {
  const v = new TextEncoder().encode(value);
  const tl = Uint8Array.of(tag, v.byteLength);
  return [...tl, ...v];
};

export const generateQRCode = (
  merchantName: string,
  merchantVatNumber: string,
  invoiceCompleteDate: Date,
  invoiceTotalTax: number,
  invoiceTotal: number,
): string => {
  const time = formatDate(invoiceCompleteDate, 'HH:mm:ss.SSS', 'en-GB');
  const date = formatDate(invoiceCompleteDate, 'yyyy-MM-dd', 'en-GB');
  const invInfo = [
    ...getTLV(1, merchantName),
    ...getTLV(2, merchantVatNumber),
    ...getTLV(3, `${date}T${time}Z`),
    ...getTLV(4, Number(invoiceTotal).toFixed(2)),
    ...getTLV(5, Number(invoiceTotalTax).toFixed(2)),
  ];
  const qr = new Qrious({
    size: 192,
    value: btoa(String.fromCharCode(...new Uint8Array(invInfo))),
  });
  return qr.toDataURL('image/jpeg');
};

export const generateBarcode = (invoiceNumber: string): string => {
  const node = document.createElement('img');
  JSBarcode(node, invoiceNumber, {
    format: 'CODE128',
    width: 1.4,
    height: 32,
  });
  return (node as HTMLImageElement).src;
};

export const getFractionDigits = (price: number): number =>
  Number.isInteger(price) ? 0 : 2;

export const getDenominationPreset = (): Denomination[] => {
  const sr = 'currency.sr';
  const halalas = 'currency.halalas';
  return [
    {
      displayValue: 'denomination.fiveHundred',
      displayUnit: sr,
      value: 500,
    },
    {
      displayValue: 'denomination.twoHundred',
      displayUnit: sr,
      value: 200,
    },
    {
      displayValue: 'denomination.hundred',
      displayUnit: sr,
      value: 100,
    },
    {
      displayValue: 'denomination.fifty',
      displayUnit: sr,
      value: 50,
    },
    {
      displayValue: 'denomination.twenty',
      displayUnit: sr,
      value: 20,
    },
    {
      displayValue: 'denomination.ten',
      displayUnit: sr,
      value: 10,
    },
    {
      displayValue: 'denomination.five',
      displayUnit: sr,
      value: 5,
    },
    {
      displayValue: 'denomination.one',
      displayUnit: sr,
      value: 1,
    },
    {
      displayValue: 'denomination.half',
      displayUnit: halalas,
      value: 0.5,
    },
    {
      displayValue: 'denomination.quarter',
      displayUnit: halalas,
      value: 0.25,
    },
  ];
};

export const downloadFile = (fileName: string, fileUrl: string): void => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = fileUrl;
  link.click();
};

export const getBase64Image = async (url: string): Promise<string> => {
  try {
    if (!url || url.startsWith('data:image')) {
      return url;
    }
    const response = await fetch(url);
    const blob = await response.blob();
    return await firstValueFrom(convertBlobToBase64(blob));
  } catch (error) {
    console.error(`Image fetching failed: ${url}`, error);
    return url;
  }
};

export const commaSeparatedStringToNumber = (value: string): number =>
  Number(value.replace(/,/gi, ''));

export const getCompositeProductPrice = (
  variant: OfflinePosTypes.ProductVariant,
  prop = 'retailPrice',
): number =>
  variant.product.variantToComposites.reduce(
    (price: number, child: OfflinePosTypes.VariantToComposite) => {
      const [stock] = child.productVariant.productVariantToStockLocations;
      const variantPrice = stock[prop] || 0;
      return commonCalculationService.add(
        price,
        commonCalculationService.multiply(child.rate, variantPrice),
      );
    },
    0,
  );

export const mapStatusAndTransactionType = (
  status: InvoiceEnums.InvoiceZatcaStatus,
  type: InvoiceEnums.InvoiceType,
): ZatcaTransactionStatus => {
  switch (status) {
    case InvoiceEnums.InvoiceZatcaStatusConstant.Rejected:
      return ZatcaTransactionStatus.Failed;

    case InvoiceEnums.InvoiceZatcaStatusConstant.Pending:
      return ZatcaTransactionStatus.Pending;

    case InvoiceEnums.InvoiceZatcaStatusConstant.NotSubmitted:
      return ZatcaTransactionStatus.NotSubmitted;

    case InvoiceEnums.InvoiceZatcaStatusConstant.Completed:
      return type === InvoiceEnums.InvoiceTypeConstant.B2B
        ? ZatcaTransactionStatus.Cleared
        : ZatcaTransactionStatus.Reported;

    default:
      return ZatcaTransactionStatus.NotSubmitted;
  }
};

export const mapToInvoiceCustomer = (
  customer: CustomerTypes.GetCustomerOutput,
  country: string,
): InvoiceTypes.Customer => {
  const formattedAddress = [
    customer.address?.buildingNumber,
    customer.address?.streetName,
    customer.address?.district,
    customer.address?.city,
    customer.address?.zip,
    customer.address?.additionalNumber,
    country,
  ]
    .filter((part) => !!part)
    .map((part, index) => {
      // Skip comma after buildingNumber, city, and additionalNumber
      if (index === 0 || index === 2 || index === 4) {
        return part;
      }
      return ` ${part}, `;
    })
    .join('');
  return {
    id: customer.id,
    code: customer.code,
    name: customer.name,
    mobileNumber: customer.mobileNumber,
    vatNumber: customer.vatNumber,
    nationalId: customer.nationalId,
    commercialRegisterNumber: customer.commercialRegisterNumber,
    address: `${formattedAddress}`.trim(),
    totalPaid: customer.totalPaid,
    debitAmount: customer.debitAmount,
    CustomFieldsData:
      customer.customFieldsData as InvoiceSnapshotTypes.InvoiceCustomField[],
  };
};

const getAvailableLocationQuantity = (
  variant: OfflinePosTypes.ProductVariant,
) => {
  if (!variant) return 0;

  if (variant && !variant.manageStockLevel) return Number.POSITIVE_INFINITY;

  const [stock] = variant.productVariantToStockLocations;

  return stock ? stock.quantity : 0;
};

/**
 * Updates the available quantity for a composite product variant based on its child variants.
 *
 * @param {OfflinePosTypes.ProductVariant} productVariant - The composite product variant to update.
 * @returns {OfflinePosTypes.ProductVariant} The updated product variant with the available quantity adjusted.
 *
 * @throws {Error} Throws an error if there is an issue with the provided data or calculations.
 *
 * @deprecated This function should be removed once the quantity issue for composite products is resolved.
 */
export const updateAvailableQuantityOfCompositeProduct = (
  productVariant: OfflinePosTypes.ProductVariant,
): OfflinePosTypes.ProductVariant => {
  const [variantLocationDetails] =
    productVariant.productVariantToStockLocations;

  const maxToSell = productVariant.product.variantToComposites.reduce(
    (quantity, childVariant) => {
      const availableLocationQuantity = getAvailableLocationQuantity(
        childVariant.productVariant,
      );

      const availableQuantityByRate = availableLocationQuantity
        ? availableLocationQuantity / childVariant.rate
        : 0;

      return Math.min(quantity, availableQuantityByRate || 0);
    },
    Infinity,
  );

  variantLocationDetails.quantity = maxToSell;

  return productVariant;
};

export const isB2BTemplateSelected = (
  customer: Customer,
  isB2BSetting: boolean,
): boolean => {
  const isBusinessCustomer =
    customer &&
    !!(
      customer.vatNumber ||
      customer.commercialRegisterNumber ||
      customer.nationalId
    );

  return isB2BSetting && isBusinessCustomer;
};

export const playErrorNotificationAudio = (): void => {
  const audio = new Audio('../../../assets/audio/pristine.mp3');
  audio.play();
};